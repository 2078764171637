import { defaultTheme } from 'constants/theme/defaultTheme';
import { GetStartedFooterProps } from 'features/onboarding/Welcome/components/GetStartedFooter/GetStartedFooter';
import styled from 'styled-components';
import { breakpoints } from 'stylesheet';

export const Styles = {
  Container: styled.div<Pick<GetStartedFooterProps, 'show'>>`
    position: fixed;
    transform: translateY(200px);
    z-index: 3;
    width: 100%;
    padding: ${defaultTheme.spacing(4)};
    opacity: 0;
    background-color: ${defaultTheme.colors.white};
    transition: all 0.3s ease-out;
    @media (max-width: ${breakpoints.small}) {
      transform: ${props => (props.show ? `translateY(0px)` : `translateY(200px)`)};
      bottom: 0px;
      opacity: 1;
    }
  `,
};
