import { DefaultWelcomeHeader } from 'features/onboarding/Welcome/components/DefaultWelcomeHeader/DefaultWelcomeHeader';
import { PromoWelcomeHeader } from 'features/onboarding/Welcome/components/PromoWelcomeHeader/PromoWelcomeHeader';
import { FormularyLocal } from 'features/onboarding/Welcome/helpers/getFormulary';
import { GroupChatLocal } from 'features/onboarding/Welcome/helpers/getGroupChat';
import { LandingPageTypes, QueryParamAOSValues } from 'features/onboarding/Welcome/Welcome';
import { useRouter } from 'next/router';
import React, { FC, useEffect, useState } from 'react';

type WelcomeHeaderProps = {
  type: LandingPageTypes;
  setShowFooter: React.Dispatch<React.SetStateAction<boolean>>;
  showFooter: boolean;
  onClick: () => void;
  imageType: QueryParamAOSValues;
  formulary?: FormularyLocal;
  groupChat?: GroupChatLocal;
};

export const WelcomeHeader: FC<WelcomeHeaderProps> = ({
  type,
  setShowFooter,
  showFooter,
  onClick,
  imageType,
  formulary,
  groupChat,
}) => {
  const [imageOneX, setImageOneX] = useState(0);
  const [imageTwoY, setImageTwoY] = useState(0);
  const { query } = useRouter();

  useEffect(() => {
    const updateUIOnScroll = () => {
      const hidePos = 400;
      if (!showFooter && window.scrollY > hidePos) {
        setShowFooter(true);
      }
      if (showFooter && window.scrollY <= hidePos) {
        setShowFooter(false);
      }

      const startPositionY = 0;
      const endPositionY = 300;

      const imageOneXDistanceTarget = 30;
      const imageTwoYDistanceTarget = 130;

      if (window.scrollY >= startPositionY && window.scrollY < endPositionY) {
        const movementPercentage = (window.scrollY / endPositionY) * 100;
        const posX = (imageOneXDistanceTarget / 100) * movementPercentage;
        const posY = (imageTwoYDistanceTarget / 100) * movementPercentage;
        requestAnimationFrame(() => {
          setImageOneX(posX);
          setImageTwoY(posY);
        });
      }
    };

    window.addEventListener('scroll', updateUIOnScroll);
    return () => window.removeEventListener('scroll', updateUIOnScroll);
  }, [showFooter]);

  if (type === LandingPageTypes.formulary) {
    return (
      <PromoWelcomeHeader
        onClick={onClick}
        logo={formulary?.formularyWideLogoUrl}
        description="Save time with instant access to your organization's formulary."
        title={`${formulary?.formularyName} just invited you to join vTail`}
        image="formulary-welcome-phones"
      />
    );
  }

  if (type === LandingPageTypes.groupChat) {
    return (
      <PromoWelcomeHeader
        onClick={onClick}
        image="welcome-groupchat-phones"
        title="You have just been invited to join vTail"
        description={`Become part of "${groupChat?.name}" group discussions.`}
      />
    );
  }

  return (
    <DefaultWelcomeHeader
      imageOneX={imageOneX}
      imageTwoY={imageTwoY}
      imageType={imageType}
      onClick={onClick}
      query={query}
    />
  );
};
