import { DeepLinkData } from 'branch-sdk';
import { useEffect } from 'react';
import { isMobile } from 'react-device-detect';
import { handleBranchAttribution } from 'services/branch/branch';
import { BranchParams } from 'services/branch/types';
import { localStorageClass, LocalStorageItems } from 'utils/localStorage/localStorage';
import { loadBranchModule } from 'services/branch/lib';
import { getBranch } from 'services/branch/getBranch';

export const useBranchAttributes = (setLoading: React.Dispatch<React.SetStateAction<boolean>>) => {
  useEffect(() => {
    localStorageClass.removeItem(LocalStorageItems.branch);

    const loadBranch = async () => {
      if (process.env.BRANCH_KEY) {
        await loadBranchModule();
        const branch = getBranch();
        branch.init(process.env.BRANCH_KEY, undefined, (_error, params) => {
          if (params) {
            const branchParams = params as unknown as BranchParams;
            if (isMobile) {
              // This copies the link to a deeplink view. So we can later call
              // branch.deepviewCta if you don't do this the link data doesn't
              // get brought over when going website > app store > app
              const deepLink = {
                ...branchParams,
                data: branchParams.data_parsed,
              } as unknown as DeepLinkData;
              branch.deepview(deepLink, {
                make_new_link: false,
                open_app: false,
              });
            }
            handleBranchAttribution(branchParams);
          }
          setLoading(false);
        });
      }
    };

    loadBranch();
  }, []);
};
