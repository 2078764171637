import React from 'react';
import { Text } from 'atoms/Text/Text';
import { Styles } from './styles';
import { defaultTheme } from 'constants/theme/defaultTheme';
import { Badge } from 'atoms/Badge/Badge';

export type InfoCirclesProps = {
  title: string;
  subtitle: string;
  icon: string;
  last?: boolean;
  badge?: string;
};

export const InfoCircles = ({ title, subtitle, icon, last, badge }: InfoCirclesProps) => {
  return (
    <Styles.Container last={last}>
      <Styles.Circle>
        <img src={icon} alt="icon" height={30} width={30} />
      </Styles.Circle>
      <Text.h1 center>{title}</Text.h1>
      <Styles.Text>
        <Text.body2 center color={defaultTheme.colors.darkGrey}>
          {subtitle}
        </Text.body2>
      </Styles.Text>
      {badge && (
        <Styles.BadgeWrapper>
          <Badge text={badge} />
        </Styles.BadgeWrapper>
      )}
    </Styles.Container>
  );
};
