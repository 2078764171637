import { defaultTheme } from 'constants/theme/defaultTheme';
import styled from 'styled-components';
import { breakpoints } from 'stylesheet';

const blueColHeight = 514;

export const Styles = {
  InfoRow: styled.div<{ first?: boolean; removeMinHeight?: boolean; isFormulary?: boolean }>`
    display: flex;
    box-sizing: border-box;
    max-width: 1288px;
    flex-direction: row;
    ${props => !props.removeMinHeight && `min-height: ${blueColHeight}px;`}
    width: 100%;
    @media (max-width: ${breakpoints.small}) {
      min-height: auto;
      flex-direction: column;
      ${props => (props.first ? { paddingTop: defaultTheme.spacing(18) } : null)}
    }
  `,
};
