import { localStorageClass, LocalStorageItems } from 'utils/localStorage/localStorage';
import { CustomEventName, TrackingEvent, UserAttributeType } from 'services/analytics/events';
import { logDevInfo, logError } from 'services/logging';
import { logTrackingEvent, trackUserAttribute } from 'services/analytics/helpers';
import { BranchParams } from 'services/branch/types';
import { isString } from '../../types/guards/isString';

export enum BranchLinkTypes {
  referral = 'referral',
  marketing = 'marketing',
  formulary = 'formulary',
  noBranchLink = 'noBranchLink',
  groupChat = 'groupChat',
}

const branchDevLog = true;

type SourceType = 'UserReferral' | 'MarketingLink' | 'Formulary' | 'GroupChat';

type AttributionEventData = {
  [key: string]: string;
  linkTitle: string;
  attributionType: SourceType;
};

const logInstallAttributionEvent = (attributionEventData: AttributionEventData) => {
  const trackingEvent: TrackingEvent = {
    name: CustomEventName.Install_Attributed,
    data: attributionEventData,
  };
  branchDevLog && logDevInfo(['Branch: Logging install attributes', trackingEvent]);
  logTrackingEvent(trackingEvent);
};

const trackUserAttribution = ({
  attribution,
  attributionType,
}: {
  attribution: string;
  attributionType: SourceType;
}) => {
  trackUserAttribute({ userAttribute: UserAttributeType.Attribution, value: attribution });
  trackUserAttribute({
    userAttribute: UserAttributeType.AttributionType,
    value: attributionType,
  });
};
const actionBranchUserReferralOnboarding = (branchParams: BranchParams) => {
  const referrerUserId = branchParams.data_parsed.referrer_user_id;
  localStorageClass.setItem(LocalStorageItems.isUserReferred, true);
  localStorageClass.setItem(LocalStorageItems.referrerUserId, referrerUserId);
  branchDevLog && logDevInfo(['Branch: referral action with ', referrerUserId]);

  if (isString(referrerUserId)) {
    logInstallAttributionEvent({ linkTitle: referrerUserId, attributionType: 'UserReferral' });
    trackUserAttribution({ attribution: referrerUserId, attributionType: 'UserReferral' });
    trackUserAttribute({
      userAttribute: UserAttributeType.UserReferralAttribution,
      value: referrerUserId,
    });
  }
};

export const actionBranchGroupChat = (branchParams: BranchParams) => {
  const channelId = branchParams.data_parsed.group_chat_id;
  const channelName = branchParams.data_parsed.group_chat_name;
  localStorageClass.setItem(LocalStorageItems.groupChatInvite, { channelId, channelName });
  branchDevLog && logDevInfo(['Branch: Group chat onboarding action with ', channelId]);

  if (isString(channelId) && isString(channelName)) {
    logInstallAttributionEvent({ linkTitle: channelName, channelId, attributionType: 'GroupChat' });
    trackUserAttribution({ attribution: channelName, attributionType: 'GroupChat' });
  }
};

const actionBranchFormulary = (branchParams: BranchParams) => {
  const formularyId = branchParams.data_parsed.formulary_id;
  const formularyName = branchParams.data_parsed.formulary_name;
  localStorageClass.setItem(LocalStorageItems.formularyId, formularyId);
  branchDevLog && logDevInfo(['Branch: formulary action with ', formularyId, formularyName]);

  if (isString(formularyName)) {
    logInstallAttributionEvent({ linkTitle: formularyName, attributionType: 'Formulary' });
    trackUserAttribution({ attribution: formularyName, attributionType: 'Formulary' });
    trackUserAttribute({
      userAttribute: UserAttributeType.FormularyName,
      value: formularyName,
    });
  }
};

const actionBranchMarketingCampaign = (branchParams: BranchParams) => {
  const linkTitle = branchParams.data_parsed.$marketing_title;
  branchDevLog && logDevInfo(['Branch: marketing action']);

  logInstallAttributionEvent({ linkTitle, attributionType: 'MarketingLink' });
  trackUserAttribution({ attribution: linkTitle, attributionType: 'MarketingLink' });
};

const getBranchLinkType = (branchParams: BranchParams): BranchLinkTypes => {
  let type = BranchLinkTypes.noBranchLink;

  if (branchParams.data_parsed.$marketing_title) {
    type = BranchLinkTypes.marketing;
  }

  if (branchParams.data_parsed.formulary_id) {
    type = BranchLinkTypes.formulary;
  }

  if (branchParams.data_parsed.referrer_user_id) {
    type = BranchLinkTypes.referral;
  }

  if (branchParams.data_parsed.group_chat_id) {
    type = BranchLinkTypes.groupChat;
  }

  branchDevLog && logDevInfo(`Branch: Internal link type: ${type}`);

  return type;
};

export const handleBranchAttribution = (branchParams: BranchParams) => {
  branchDevLog && logDevInfo('Branch: Initialisation');

  if (!branchParams.referring_link) {
    branchDevLog && logDevInfo('Branch: No referring link provided');
    return;
  }

  try {
    const type = getBranchLinkType(branchParams);

    switch (type) {
      case BranchLinkTypes.referral:
        return actionBranchUserReferralOnboarding(branchParams);
      case BranchLinkTypes.marketing:
        return actionBranchMarketingCampaign(branchParams);
      case BranchLinkTypes.formulary:
        return actionBranchFormulary(branchParams);
      case BranchLinkTypes.groupChat:
        return actionBranchGroupChat(branchParams);
      case BranchLinkTypes.noBranchLink:
        branchDevLog && logDevInfo('Branch: No branch link provided');
        break;
    }
  } catch (error) {
    logError({ error, errorName: 'LogAttributionDataError' });
  }
};
