import React, { useMemo, useState } from 'react';
import { isMobile } from 'react-device-detect';
import { Text } from 'atoms/Text/Text';
import { useRouter } from 'next/router';
import { Styles } from './styles';
import { PATHS } from 'constants/paths';
import { HeaderGrad } from 'features/onboarding/Welcome/components/HeaderGrad/HeaderGrad';
import { defaultTheme } from 'constants/theme/defaultTheme';
import { useModal } from 'contexts/ModalProvider/ModalProvider';
import { Button } from 'atoms/Button/Button';
import { GetStartedFooter } from 'features/onboarding/Welcome/components/GetStartedFooter/GetStartedFooter';
import { useOnboarding } from 'contexts/OnboardingProvider/OnboardingProvider';
import { useAuth } from 'contexts/AuthProvider/AuthProvider';
import { determineNextOnboardingPage } from 'contexts/OnboardingProvider/onboardingUtils';
import { Popup } from '../../products/components/Popup/Popup';
import Edit from 'assets/svgs/edit.svg';
import { getBranch } from 'services/branch/getBranch';
import { useBranchAttributes } from 'features/onboarding/Welcome/useBranchAttributes';
import { getFormulary } from 'features/onboarding/Welcome/helpers/getFormulary';
import { getGroupChat } from 'features/onboarding/Welcome/helpers/getGroupChat';
import { WelcomeHeader } from 'features/onboarding/Welcome/components/WelcomeHeader/WelcomeHeader';
import { InfoCircleList } from 'features/onboarding/Welcome/components/InfoCircleList/InfoCircleList';
import { iconRows } from 'features/onboarding/Welcome/content';

export enum welcomeTestIDs {
  button = 'welcome button',
  image = 'welcome phone image',
  promoHeader = 'formulary header',
}

export enum LandingPageTypes {
  default = 'default',
  formulary = 'formulary',
  groupChat = 'groupChat',
}

const paramAOSMap = ['dental', 'orthopedics', 'woundcare'] as const;
export type QueryParamAOSValues = typeof paramAOSMap[number];

const queryToAosMap = (key: string | string[] | undefined): QueryParamAOSValues => {
  const isQueryParamAOS = (key: unknown): key is QueryParamAOSValues =>
    typeof key === 'string' && paramAOSMap.includes(key as QueryParamAOSValues);
  if (isQueryParamAOS(key)) {
    return key;
  }
  return 'woundcare';
};

export const Welcome: React.FC = () => {
  const { query, push } = useRouter();
  const { user } = useOnboarding();
  const { handleModal } = useModal();
  const { user: authUser } = useAuth();
  const [showFooter, setShowFooter] = useState(false);
  const [loading, setLoading] = useState(true);

  const onClick = async () => {
    if (isMobile) {
      const branch = getBranch();
      branch.deepviewCta();
      return false;
    }

    // when a user has already started onboarding, this prompts them to continue
    if (authUser) {
      const nextOnboardingPage = determineNextOnboardingPage(user);

      if (nextOnboardingPage) {
        handleModal(
          <Popup
            title="Continue where you left off?"
            buttonText="Okay"
            onClick={() => push(nextOnboardingPage)}
            icon={() => (
              <Styles.PopupIconWrapper>
                <Edit color={defaultTheme.colors.white} />
              </Styles.PopupIconWrapper>
            )}
          />,
        );
        return;
      }
    }

    push(PATHS.ONBOARDING_INTRO);
  };

  useBranchAttributes(setLoading);

  const formulary = getFormulary();
  const groupChat = getGroupChat();
  const imageType = queryToAosMap(query.aosName);
  const landingPage = useMemo(() => {
    if (groupChat) {
      return LandingPageTypes.groupChat;
    }
    if (formulary) {
      return LandingPageTypes.formulary;
    }
    return LandingPageTypes.default;
  }, [groupChat, formulary]);

  if (loading) {
    return null;
  }

  const waterFullPath = `/images/welcome-phone-waterfull-${imageType}.png 1x, /images/welcome-phone-waterfull-${imageType}@2x.png 2x`;

  return (
    <>
      <Styles.Container>
        <WelcomeHeader
          formulary={formulary}
          groupChat={groupChat}
          imageType={imageType}
          setShowFooter={setShowFooter}
          showFooter={showFooter}
          type={landingPage}
          onClick={onClick}
        />
        <Styles.InfoRowContainer>
          <>
            {landingPage === LandingPageTypes.default && (
              <InfoCircleList data={iconRows.one.default} />
            )}
            {landingPage === LandingPageTypes.formulary && (
              <InfoCircleList data={iconRows.one.formulary} />
            )}
            {landingPage === LandingPageTypes.groupChat && (
              <InfoCircleList data={iconRows.one.groupChat} />
            )}
          </>
        </Styles.InfoRowContainer>
        <HeaderGrad>
          <Styles.TextTwo>
            {query.referrer && imageType === 'orthopedics' ? (
              <Text.display responsive color={defaultTheme.colors.white}>
                Join <b>{query.referrer}</b> <br />
                and your fellow Orthopedic surgeons for the soft launch of vTail
              </Text.display>
            ) : query.referrer ? (
              <Text.display responsive color={defaultTheme.colors.white}>
                Join <b>{query.referrer}</b> <br />
                and thousands of other clinicians
              </Text.display>
            ) : (
              <Text.display responsive color={defaultTheme.colors.white}>
                Join thousands of other clinicians
              </Text.display>
            )}

            <Styles.ButtonWrapper>
              <Button text="Get started" large block onClick={onClick} />
            </Styles.ButtonWrapper>
          </Styles.TextTwo>
          <Styles.ImageTwo>
            <img
              loading="lazy"
              srcSet={waterFullPath}
              alt="Image of phones with app on"
              height={477}
              width={698}
            />
          </Styles.ImageTwo>
        </HeaderGrad>

        {landingPage === LandingPageTypes.default && <InfoCircleList data={iconRows.two.default} />}

        {landingPage === LandingPageTypes.formulary && (
          <Styles.Margin>
            <InfoCircleList data={iconRows.two.formulary} removeMinHeight />
          </Styles.Margin>
        )}
        {landingPage === LandingPageTypes.groupChat && (
          <Styles.Margin>
            <InfoCircleList data={iconRows.two.groupChat} removeMinHeight />
          </Styles.Margin>
        )}

        <HeaderGrad center>
          <Styles.WrapperThird>
            <Text.display responsive center color={defaultTheme.colors.white}>
              Get started for free in just 2 minutes
            </Text.display>
            <Styles.ButtonWrapper>
              <Button text="Get started" large block onClick={onClick} />
            </Styles.ButtonWrapper>
          </Styles.WrapperThird>
        </HeaderGrad>
      </Styles.Container>
      <GetStartedFooter show={showFooter} onClick={onClick} />
    </>
  );
};
