import { defaultTheme } from 'constants/theme/defaultTheme';
import styled from 'styled-components';

export const Styles = {
  Wrapper: styled.div`
    padding: ${defaultTheme.spacing(4)};
    width: 100%;
    max-width: 435px;
    background: radial-gradient(circle at top right, #19406f, #212240 50%);
    border-radius: 16px;
    background-color: transparent;
  `,
  Content: styled.div`
    padding: ${defaultTheme.spacing(7)};
    display: flex;
    flex-direction: column;
    align-items: center;
  `,
  Close: styled.div`
    cursor: pointer;
    position: absolute;
    right: 28px;
    top: 16px;
  `,
  SecondaryButton: styled.button`
    display: flex;
    background-color: transparent;
    border: 0;
    align-items: 'center';
    justify-content: center;
    text-align: center;
    min-width: 185px;
    cursor: pointer;
  `,
  Buttons: styled.span`
    display: flex;
    align-items: center;
    justify-content: center;
  `,
};
