import { localStorageClass, LocalStorageItems } from 'utils/localStorage/localStorage';

export type FormularyLocal = {
  formularyName: string;
  formularyWideLogoUrl: string;
};

export const getFormulary = (): FormularyLocal | undefined => {
  const branchObj = localStorageClass.getItem(LocalStorageItems.branch);
  if (branchObj && branchObj.data) {
    const data = branchObj && JSON.parse(branchObj.data);

    if (data && data.formulary_id && data.formulary_name && data.formulary_wide_logo_url) {
      return {
        formularyName: data.formulary_name,
        formularyWideLogoUrl: data.formulary_wide_logo_url,
      };
    }
  }
};
