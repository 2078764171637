import { Styles } from './styles';
import React, { FC } from 'react';
import { Button } from 'atoms/Button/Button';

export type GetStartedFooterProps = { show: boolean; onClick: () => void };

export const GetStartedFooter: FC<GetStartedFooterProps> = ({ show, onClick }) => {
  return (
    <Styles.Container show={show}>
      <Button text="Get started" large block onClick={onClick} />
    </Styles.Container>
  );
};
