import { defaultTheme } from 'constants/theme/defaultTheme';
import styled from 'styled-components';
import { breakpoints } from 'stylesheet';

const blueColHeight = 514;

export const Styles = {
  Container: styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    @media (max-width: ${breakpoints.small}) {
      padding-bottom: ${defaultTheme.spacing(20)};
    }
  `,
  Margin: styled.div`
    margin: ${defaultTheme.spacing(20)} 0;
    @media (max-width: ${breakpoints.small}) {
      margin: 0;
    }
  `,
  ButtonWrapper: styled.div`
    margin-top: ${defaultTheme.spacing(7)};
    width: 216px;
    text-align: center;
  `,
  TextTwo: styled.div`
    padding: ${defaultTheme.spacing(15)} 0;
    justify-content: center;
    flex-direction: column;
    height: 100%;
    display: flex;
    @media (max-width: ${breakpoints.medium}) {
      align-items: center;
      text-align: center;
    }
  `,
  ImageTwo: styled.div`
    align-items: flex-end;
    height: 100%;
    display: flex;
    img {
      max-width: 100%;
      height: auto;
    }
  `,
  WrapperThird: styled.div`
    padding: ${defaultTheme.spacing(15)} 0;
    display: flex;
    align-items: center;
    height: ${blueColHeight}px;
    justify-content: center;
    flex-direction: column;
    @media (max-width: ${breakpoints.medium}) {
      height: auto;
    }
  `,
  PopupIconWrapper: styled.div`
    height: 80px;
    width: 80px;
    background-color: rgba(255, 255, 255, 0.1);
    border-radius: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  `,
  InfoRowContainer: styled.div`
    @media (max-width: ${breakpoints.small}) {
      padding-top: ${defaultTheme.spacing(8)};
    }
  `,
};
