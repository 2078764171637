import { Logo } from 'assets/svgs/Logo';
import { Button } from 'atoms/Button/Button';
import { Text } from 'atoms/Text/Text';
import { defaultTheme } from 'constants/theme/defaultTheme';
import { ParsedUrlQuery } from 'querystring';
import React from 'react';
import { QueryParamAOSValues, welcomeTestIDs } from '../../Welcome';
import { HeaderGrad } from '../HeaderGrad/HeaderGrad';
import { Styles } from './styles';

type DefaultWelcomeHeaderProps = {
  onClick(): void;
  imageOneX: number;
  imageTwoY: number;
  imageType: QueryParamAOSValues;
  query: ParsedUrlQuery;
};

export const DefaultWelcomeHeader: React.FC<DefaultWelcomeHeaderProps> = ({
  onClick,
  imageOneX,
  imageTwoY,
  imageType,
  query,
}) => {
  return (
    <HeaderGrad>
      <Styles.Hero>
        <Styles.TextCol>
          <Styles.Logo>
            <Logo color="white" />
          </Styles.Logo>
          <Styles.Header>
            {query.referrer && imageType === 'orthopedics' ? (
              <Text.display responsive color={defaultTheme.colors.white}>
                Join {query.referrer} <br /> and your fellow Orthopedic surgeons for the soft launch
                of vTail
              </Text.display>
            ) : query.referrer ? (
              <Text.display responsive color={defaultTheme.colors.white}>
                Join {query.referrer} <br /> and thousands of your fellow HCPs
              </Text.display>
            ) : (
              <Text.display responsive color={defaultTheme.colors.white}>
                You've been invited to join vTail
              </Text.display>
            )}
          </Styles.Header>
          <Text.h1 color={defaultTheme.colors.white} regular responsive>
            A new digital ecosystem purpose-built for healthcare for connecting, communicating and
            collaborating.
          </Text.h1>
          <Styles.ButtonWrapper>
            <Button
              text="Get started"
              testID={welcomeTestIDs.button}
              large
              block
              onClick={onClick}
            />
          </Styles.ButtonWrapper>
        </Styles.TextCol>
        <Styles.Col>
          <Styles.FloatPhones>
            <img
              data-testid={welcomeTestIDs.image}
              style={{ transform: `translateX(${imageOneX}px)` }}
              srcSet={`/images/welcome-phone-one-${imageType}.png 1x, /images/welcome-phone-one-${imageType}@2x.png 2x`}
              alt="Image of phones with app on"
              height={571}
              width={343}
            />
            <img
              style={{ transform: `translateY(${imageTwoY}px)` }}
              srcSet={`/images/welcome-phone-two-${imageType}.png 1x, /images/welcome-phone-two-${imageType}@2x.png 2x`}
              alt="Image of phones with app on"
              height={576}
              width={338}
            />
          </Styles.FloatPhones>
        </Styles.Col>
      </Styles.Hero>
    </HeaderGrad>
  );
};
