import { Styles } from './styles';
import React, { FC } from 'react';
import DesktopLogo from 'assets/svgs/desktop-logo.svg';

export type HeaderGradProps = {
  height?: number;
  center?: boolean;
  showBgImage?: boolean;
};

export const HeaderGrad: FC<HeaderGradProps> = ({ children, height, showBgImage }) => {
  return (
    <Styles.Container>
      <Styles.Wrapper>
        <Styles.Content center height={height} showBgImage={showBgImage}>
          {showBgImage && (
            <Styles.Logo>
              <DesktopLogo />
            </Styles.Logo>
          )}
          {children}
        </Styles.Content>
      </Styles.Wrapper>
    </Styles.Container>
  );
};
