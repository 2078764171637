import { Button } from 'atoms/Button/Button';
import { Text } from 'atoms/Text/Text';
import { Spacer } from 'components/Spacer/Spacer';
import React, { useState } from 'react';
import { defaultTheme } from 'constants/theme/defaultTheme';
import { Styles } from './styles';
import { useModal } from 'contexts/ModalProvider/ModalProvider';
import { TEST_IDS } from 'constants/testIds';
import CloseIcon from 'assets/svgs/closeIcon.svg';

export type PopupProps = {
  title: string;
  description?: string;
  buttonText: string;
  onClick: () => void;
  onClickSecondary?: () => void;
  secondaryButtonText?: string;
  icon(): React.ReactElement;
};

export const Popup: React.FC<PopupProps> = ({
  title,
  description,
  buttonText,
  onClick,
  icon,
  onClickSecondary,
  secondaryButtonText,
}) => {
  const { handleModal } = useModal();
  const [loading, setLoading] = useState(false);

  const onButtonClick = async () => {
    setLoading(true);
    await onClick();
    handleModal();
    setLoading(false);
  };

  const onSecondaryButtonClick = async () => {
    onClickSecondary && (await onClickSecondary());
    handleModal();
  };

  return (
    <Styles.Wrapper>
      <Styles.Close onClick={handleModal} data-testid={TEST_IDS.popup.closeButton}>
        <CloseIcon color={defaultTheme.colors.white} />
      </Styles.Close>
      <Styles.Content>
        {icon()}
        <Spacer.Column numberOfSpaces={4} />
        <Text.h1
          responsive
          data-testid={TEST_IDS.popup.title}
          color={defaultTheme.colors.white}
          center
        >
          {title}
        </Text.h1>

        {description && (
          <>
            <Spacer.Column numberOfSpaces={2} />
            <Text.body3 center color={defaultTheme.colors.white}>
              {description}
            </Text.body3>
          </>
        )}
        <Spacer.Column numberOfSpaces={8} />
        <Styles.Buttons>
          {secondaryButtonText && (
            <Styles.SecondaryButton onClick={onSecondaryButtonClick}>
              <Text.h2 color="white">{secondaryButtonText}</Text.h2>
            </Styles.SecondaryButton>
          )}
          <Button
            text={buttonText}
            onClick={onButtonClick}
            large
            loading={loading}
            minWidth={185}
            testID={TEST_IDS.popup.button}
          />
        </Styles.Buttons>
      </Styles.Content>
    </Styles.Wrapper>
  );
};
