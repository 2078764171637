import { defaultTheme } from 'constants/theme/defaultTheme';
import { HeaderGradProps } from 'features/onboarding/Welcome/components/HeaderGrad/HeaderGrad';
import styled from 'styled-components';
import { breakpoints } from 'stylesheet';

export const Styles = {
  Container: styled.div<{ removePadding?: boolean }>`
    width: 100%;
    justify-content: center;
    background-image: radial-gradient(circle at top right, #19406f, #212240 50%);
    @media (max-width: ${breakpoints.small}) {
      ${props => props.removePadding && `padding: 0;`}
    }
  `,
  Wrapper: styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
  `,
  Content: styled.div<HeaderGradProps>`
    max-width: ${breakpoints.large};
    width: 100%;
    position: relative;
    padding: 0 ${defaultTheme.spacing(4)};
    display: flex;
    align-items: center;
    justify-content: ${props => (props.center ? `center` : 'flex-start')};
    @media (max-width: ${breakpoints.medium}) {
      flex-direction: column;
      ${props => props.showBgImage && `overflow: hidden;`}
    }
  `,
  Logo: styled.div`
    position: absolute;
    right: -200px;
    top: -80px;
    @media (max-width: ${breakpoints.medium}) {
      top: 480px;
      left: -300px;
    }
  `,
};
