// @ts-nocheck

/**
 * Branch uses window directly so can not be loaded in next.js
 * at startup. Using dynamic imports "await (import('branch-sdk')"
 * works fine locally but does not work in a lambda environment.
 * Sadly means we have to import the script directly as per below.
 */
// prettier-ignore
export const loadBranchModule = async () => {
  return new Promise((resolve) => {
    const branchLoaded = () => {
      resolve(true);
    }
        
    (function(b,r,a,n,c,h,_,s,d,k){if(!b[n]||!b[n]._q){for(;s<_.length;)c(h,_[s++]);d=r.createElement(a);d.async=1;d.src="https://cdn.branch.io/branch-latest.min.js";d.onload=branchLoaded;k=r.getElementsByTagName(a)[0];k.parentNode.insertBefore(d,k);b[n]=h}})(window,document,"script","branch",function(b,r){b[r]=function(){b._q.push([r,arguments])}},{_q:[],_v:1},"addListener applyCode autoAppIndex banner closeBanner closeJourney creditHistory credits data deepview deepviewCta first getCode init link logout redeem referrals removeListener sendSMS setBranchViewData setIdentity track validateCode trackCommerceEvent logEvent disableTracking qrCode".split(" "), 0);
  });
};
