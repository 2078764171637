import { defaultTheme } from 'constants/theme/defaultTheme';
import { InfoCirclesProps } from 'features/onboarding/Welcome/components/InfoCircles/InfoCircles';
import styled from 'styled-components';
import { breakpoints } from 'stylesheet';

export const Styles = {
  Container: styled.div<Pick<InfoCirclesProps, 'last'>>`
    display: flex;
    flex: 1;
    align-items: center;
    position: relative;
    justify-content: center;
    flex-direction: column;
    padding: ${defaultTheme.spacing(15)} 3.4% ${defaultTheme.spacing(15)} 3.4%;
    @media (max-width: ${breakpoints.small}) {
      padding-bottom: ${props => (props.last ? defaultTheme.spacing(15) : 0)};
    }
  `,
  Circle: styled.span`
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: ${defaultTheme.colors.greyLight};
    height: 80px;
    width: 80px;
    margin-bottom: ${defaultTheme.spacing(5)};
    border-radius: 80px;
  `,
  Text: styled.div`
    margin-top: ${defaultTheme.spacing(2)};
  `,
  BadgeWrapper: styled.div`
    padding-top: ${defaultTheme.spacing(2)};
    @media (min-width: ${breakpoints.small}) {
      margin-bottom: -${defaultTheme.spacing(8)};
    }
  `,
};
