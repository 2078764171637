export const iconRows = {
  one: {
    default: [
      {
        title: 'Powerful product search',
        subtitle: 'Get detailed data on thousands of products',
        icon: '/images/welcome-icon-mag.svg',
      },
      {
        title: 'Chat with your rep',
        subtitle:
          'Save time with instant access to all the healthcare product information you need.',
        icon: '/images/welcome-icon-chat.svg',
      },
      {
        title: 'Clinicians in control',
        subtitle: 'You pick who you want to talk to. Never the other way around.',
        icon: '/images/welcome-icon-strong.svg',
      },
    ],
    groupChat: [
      {
        title: 'Invite only groups',
        subtitle: 'Join private group chats using invitation link.',
        icon: '/images/welcome-icon-link.svg',
      },
      {
        title: 'Chats with group members',
        subtitle: 'Participate in group and individual chats with other members.',
        icon: '/images/welcome-icon-users.svg',
      },
      {
        title: 'HIPAA-optional groups',
        subtitle: 'Share patient data in secured group chats.',
        icon: '/images/welcome-icon-hipaa.svg',
      },
    ],
    formulary: [
      {
        title: 'Your organization’s formulary',
        subtitle: 'Access products approved to use by your organization.',
        icon: '/images/welcome-icon-doc.svg',
      },
      {
        title: 'Multi-formularies',
        subtitle: 'Find different formularies from all of your organizations.',
        icon: '/images/welcome-icon-docs.svg',
        badge: 'Coming soon',
      },
      {
        title: 'Powerful search',
        subtitle: 'Discover products both inside and outside  your formularies.',
        icon: '/images/welcome-icon-mag.svg',
      },
    ],
  },
  two: {
    default: [
      {
        title: 'Stay ahead of the curve',
        subtitle: 'Read curated articles on the latest innovations',
        icon: '/images/welcome-icon-news.svg',
      },
      {
        title: 'HIPAA-enabled',
        subtitle: 'No more sharing patient data on unsecured channels.',
        icon: '/images/welcome-icon-shield.svg',
      },
      {
        title: 'Always there for you',
        subtitle: 'Get fast customer support, whenever you need.',
        icon: '/images/welcome-icon-person.svg',
      },
    ],
    formulary: [
      {
        title: 'Chat with your rep',
        subtitle:
          'Save time with instant access to all the healthcare product information you need.',
        icon: '/images/welcome-icon-chat.svg',
      },
      {
        title: 'Clinicians in control',
        subtitle: 'You pick who you want to talk to. Never the other way around.',
        icon: '/images/welcome-icon-strong.svg',
      },
      {
        title: 'Stay ahead of the curve',
        subtitle: 'Read curated articles on the latest innovations',
        icon: '/images/welcome-icon-news.svg',
      },
      {
        title: 'HIPAA-enabled',
        subtitle: 'No more sharing patient data on unsecured channels.',
        icon: '/images/welcome-icon-hipaa.svg',
      },
      {
        title: 'Always there for you',
        subtitle: 'Get fast customer support, whenever you need.',
        icon: '/images/welcome-icon-person.svg',
      },
      {
        title: 'CE courses directory',
        subtitle: 'Discover CE courses from top specialist organizations.',
        icon: '/images/welcome-icon-hat.svg',
        badge: 'Coming soon',
      },
    ],
    groupChat: [
      {
        title: 'Chat with your rep',
        subtitle:
          'Save time with instant access to all the healthcare product information you need.',
        icon: '/images/welcome-icon-chat.svg',
      },
      {
        title: 'Clinicians in control',
        subtitle: 'You pick who you want to talk to. Never the other way around.',
        icon: '/images/welcome-icon-strong.svg',
      },
      {
        title: 'Stay ahead of the curve',
        subtitle: 'Read curated articles on the latest innovations',
        icon: '/images/welcome-icon-news.svg',
      },
      {
        title: 'HIPAA-enabled',
        subtitle: 'No more sharing patient data on unsecured channels.',
        icon: '/images/welcome-icon-shield.svg',
      },
      {
        title: 'Always there for you',
        subtitle: 'Get fast customer support, whenever you need.',
        icon: '/images/welcome-icon-person.svg',
      },
      {
        title: 'Add your formulary',
        subtitle: 'Access products approved to use by your organization.',
        icon: '/images/welcome-icon-doc.svg',
      },
    ],
  },
};
