import React from 'react';
import { defaultTheme } from 'constants/theme/defaultTheme';

type unit = '%' | 'px';

interface LogoProps {
  color: 'white' | 'deepBlue';
  width?: `${number}${unit}`;
}

export const Logo: React.FC<LogoProps> = ({ color, width = '100%' }) => {
  const fillColor = defaultTheme.colors[color];

  return (
    <svg width={width} viewBox="0 0 97 40" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_170_1780)">
        <path
          d="M52.6117 3.32216C52.4584 3.06433 52.1426 2.75364 51.5073 2.75364H27.0456C26.409 2.75364 26.0932 3.06304 25.9399 3.32216C25.7414 3.65992 25.6447 4.16913 25.6447 4.88204V5.9211C25.6447 6.634 25.7414 7.14451 25.9399 7.47969C26.0919 7.73752 26.4077 8.04821 27.0456 8.04821H36.2924V38.1836C36.2924 39.2149 37.1829 39.7396 38.9382 39.7396H39.7178C41.4744 39.7396 42.3649 39.2162 42.3649 38.1836V8.04821H51.5073C52.1439 8.04821 52.4597 7.7401 52.6117 7.47969C52.8115 7.14193 52.9069 6.63271 52.9069 5.9211V4.88204C52.9069 4.17042 52.8115 3.66121 52.6117 3.32216Z"
          fill={fillColor}
        />
        <path
          d="M70.3011 36.2627C70.0975 35.2456 69.9944 33.5671 69.9944 31.2737V20.156C69.9944 17.49 69.0626 15.3693 67.2261 13.852C65.3897 12.3347 62.7155 11.5857 59.2449 11.5857C57.6076 11.5764 55.975 11.7611 54.3812 12.1361C52.8566 12.5035 51.6104 12.9676 50.6761 13.5142C49.6631 14.1073 49.1721 14.6745 49.1721 15.2701C49.1721 15.7393 49.4298 16.3865 49.9634 17.2489C50.5279 18.1655 51.0189 18.5922 51.5099 18.5922C51.622 18.5922 51.8127 18.5484 52.8953 18.0211C53.7565 17.6138 54.647 17.2712 55.5591 16.9963C56.5901 16.6765 57.8093 16.5141 59.196 16.5141C60.9577 16.5141 62.2464 16.8596 63.0248 17.5454C63.8032 18.2313 64.1847 19.1156 64.1847 20.2604V22.2393C59.0632 22.296 55.0075 23.0295 52.1259 24.4192C49.1347 25.8618 47.6153 28.4156 47.6153 32.0021C47.6153 34.5636 48.413 36.5605 49.9879 37.9322C51.5524 39.3013 53.7059 39.9948 56.3904 39.9948C58.2951 39.9948 59.9872 39.6004 61.4165 38.8204C62.6169 38.1784 63.705 37.3455 64.6383 36.3543C64.6757 36.5438 64.7208 36.7707 64.7762 37.0349C64.895 37.6229 65.0742 38.1971 65.3111 38.7482C65.418 39.0317 65.6113 39.2744 65.8636 39.4421C66.1158 39.6098 66.4144 39.694 66.7171 39.6829C67.5349 39.6733 68.3486 39.5664 69.1412 39.3644C70.1155 39.1247 70.6104 38.6915 70.6104 38.0753C70.6181 37.9709 70.582 37.6615 70.3011 36.2627ZM64.1808 26.8519V31.6888C63.3101 32.6571 62.285 33.4743 61.1471 34.1073C60.0491 34.7067 58.7449 35.0097 57.2719 35.0097C56.0991 35.0097 55.1893 34.7106 54.5655 34.1202C53.9417 33.5297 53.6324 32.6776 53.6324 31.5792C53.6324 29.6455 54.5603 28.4066 56.4677 27.7981C58.375 27.1896 60.9834 26.8725 64.1808 26.857V26.8519Z"
          fill={fillColor}
        />
        <path
          d="M77.9935 11.8448H77.478C75.7227 11.8448 74.8322 12.3695 74.8322 13.4021V38.1836C74.8322 39.2149 75.7227 39.7396 77.478 39.7396H77.9935C79.7501 39.7396 80.6406 39.2162 80.6406 38.1836V13.4021C80.6406 12.3695 79.7501 11.8448 77.9935 11.8448Z"
          fill={fillColor}
        />
        <path
          d="M77.786 1.50703C75.7537 1.50703 74.724 2.08328 74.724 3.21903V6.28465C74.724 7.42039 75.7189 7.99665 77.6881 7.99665C79.7191 7.99665 80.7501 7.42039 80.7501 6.28465V3.21903C80.745 2.0807 79.7488 1.50703 77.786 1.50703Z"
          fill={fillColor}
        />
        <path
          d="M96.6251 36.1042C96.3402 35.2327 95.9717 34.8086 95.4961 34.8086C95.2188 34.8217 94.944 34.868 94.6778 34.9465C94.2979 35.0363 93.9081 35.0774 93.5179 35.069C92.791 35.069 92.242 34.8498 91.8425 34.4012C91.443 33.9526 91.2329 33.1262 91.2329 31.9518V1.55601C91.2329 0.524687 90.3424 0 88.5871 0H88.0149C85.8705 0 85.4207 0.846977 85.4207 1.55601V32.2096C85.4207 34.9762 86.1321 36.9911 87.5355 38.1978C88.939 39.4044 90.6285 40.0026 92.5861 40.0026C95.5142 40.0026 96.9988 39.2871 96.9988 37.8742C97.0014 37.4629 96.8776 36.888 96.6251 36.1042Z"
          fill={fillColor}
        />
        <path
          d="M27.9787 12.715C25.3213 12.6969 24.1924 12.715 24.1924 12.715C24.1924 12.715 22.0917 12.9857 20.986 15.4312C20.3622 16.8093 17.4239 23.3441 14.8425 29.0834L19.0052 38.3421L19.0142 38.3563C19.3344 37.9911 19.5954 37.5778 19.7874 37.1316C20.879 34.6642 29.4273 15.1657 29.4273 15.1657C29.4273 15.1657 30.5871 12.7317 27.9787 12.715Z"
          fill="#3BE5FF"
        />
        <path
          d="M14.8425 29.0795L10.6811 38.3615C11.3357 39.1075 12.2444 39.5831 13.2302 39.6958C13.2302 39.6958 13.8746 39.7087 16.1222 39.6958C17.7615 39.6867 19.0051 38.3383 19.0051 38.3383L18.9832 38.2893L14.8425 29.0795Z"
          fill={fillColor}
        />
        <path
          d="M10.6631 38.3486C10.6331 38.3151 10.6034 38.2803 10.5742 38.2442C10.4982 38.1526 10.4247 38.0559 10.3525 37.9515C10.1726 37.6926 10.0185 37.4167 9.89247 37.1278C8.80734 34.6642 0.255228 15.1657 0.255228 15.1657C0.255228 15.1657 -0.904644 12.7317 1.70635 12.7163C4.36504 12.6982 5.49269 12.7163 5.49269 12.7163C5.49269 12.7163 7.59335 12.987 8.69909 15.4325L14.8425 29.0847L10.6812 38.3666L10.6631 38.3486Z"
          fill="#009EFF"
        />
      </g>
      <defs>
        <clipPath id="clip0_170_1780">
          <rect width="97" height="40" fill={fillColor} />
        </clipPath>
      </defs>
    </svg>
  );
};
