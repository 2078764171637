import { InfoCircles } from 'features/onboarding/Welcome/components/InfoCircles/InfoCircles';
import React, { FC } from 'react';
import { Styles } from './styles';

type InfoCircleItem = {
  title: string;
  subtitle: string;
  icon: string;
};

type InfoCircleListProps = {
  data: InfoCircleItem[];
  removeMinHeight?: boolean;
};

export const InfoCircleList: FC<InfoCircleListProps> = ({ data, removeMinHeight }) => {
  const chunked: InfoCircleItem[][] = data
    .reduce(
      (groups, curr) => {
        const arr: InfoCircleItem[] = groups[groups.length - 1];
        arr.push(curr);
        if (arr.length === 3) groups.push([]);
        return groups;
      },
      [[]],
    )
    .filter(chunk => chunk.length);

  return (
    <>
      {chunked.map((list, index) => (
        <Styles.InfoRow key={index} removeMinHeight={removeMinHeight}>
          {list.map((item, index) => (
            <InfoCircles key={item.title} {...item} last={index === list.length - 1} />
          ))}
        </Styles.InfoRow>
      ))}
    </>
  );
};
