import { defaultTheme } from 'constants/theme/defaultTheme';
import styled from 'styled-components';

export const Styles = {
  Wrapper: styled.div`
    background-color: ${defaultTheme.colors.alertRed};
    border-radius: 8px;
    padding: ${defaultTheme.spacing(1)} ${defaultTheme.spacing(4)};
  `,
};
