import styled from 'styled-components';
import { breakpoints } from 'stylesheet';
import { defaultTheme } from 'constants/theme/defaultTheme';

export const Styles = {
  Hero: styled.div`
    height: 683px;
    display: flex;
    flex: 1;
    @media (max-width: ${breakpoints.medium}) {
      flex-basis: 100vh;
      flex-direction: column;
    }
  `,
  TextCol: styled.div`
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    flex: 1;
    z-index: 1;
    padding-top: ${defaultTheme.spacing(5)};
    padding-right: ${defaultTheme.spacing(10)};
    @media (max-width: ${breakpoints.small}) {
      padding-right: 0;
    }
  `,
  FormularyText: styled.div`
    display: flex;
    flex-direction: column;
  `,
  Logo: styled.div<{ whiteBorder?: boolean }>`
    width: 97px;
    ${props =>
      props.whiteBorder &&
      `
      border-left: 1px solid ${defaultTheme.colors.white};
      padding-left: ${defaultTheme.spacing(4)};
    `};
    @media (max-width: ${breakpoints.small}) {
      width: 70px;
    }
  `,
  LogoWrapper: styled.div`
    display: flex;
    align-items: center;
    margin-bottom: ${defaultTheme.spacing(20)};
    @media (max-width: ${breakpoints.small}) {
      margin-bottom: ${defaultTheme.spacing(12)};
    }
  `,
  PromoImageWrapper: styled.div`
    padding-right: ${defaultTheme.spacing(4)};
    height: 40px;
    @media (max-width: ${breakpoints.small}) {
      height: 24px;
    }
  `,
  PromoImage: styled.img`
    height: 100%;
    width: auto;
  `,
  Header: styled.div`
    padding: ${defaultTheme.spacing(20)} 0 ${defaultTheme.spacing(5)};
    @media (max-width: ${breakpoints.small}) {
      padding-top: 54px;
    }
  `,
  Image: styled.img`
    max-width: 100%;
  `,
  ButtonWrapper: styled.div`
    margin-top: ${defaultTheme.spacing(7)};
    width: 216px;
    text-align: center;
  `,
  TextWraper: styled.div`
    margin-top: ${defaultTheme.spacing(6)};
    @media (max-width: ${breakpoints.small}) {
      margin-top: ${defaultTheme.spacing(4)};
    }
  `,
  Col: styled.div`
    flex: 1;
  `,
  ImageCol: styled.div`
    display: flex;
    flex: 1;
    position: relative;
    justify-content: flex-start;
    align-items: center;
    height: 100%;
  `,
  FloatPhones: styled.div`
    max-width: 550px;
    height: 100%;
    position: relative;
    margin: 0 auto;

    img {
      width: 55%;
      height: auto;
    }

    > :nth-child(1) {
      top: 52px;
      left: 0;
      z-index: 1;
      transition: all 0.3s ease-out;
      position: absolute;
    }
    > :nth-child(2) {
      z-index: 2;
      top: 20px;
      right: 0;
      transition: all 0.3s ease-out;
      position: absolute;
    }
  `,
  PromoHeader: styled.span`
    width: 100%;
    overflow: hidden;
  `,
};
