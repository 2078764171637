import React from 'react';
import { Text } from 'atoms/Text/Text';
import { defaultTheme } from 'constants/theme/defaultTheme';
import { Styles } from './styles';

type BadgeProps = {
  text: string;
};

export const Badge: React.FC<BadgeProps> = ({ text }) => {
  return (
    <Styles.Wrapper>
      <Text.caption3 color={defaultTheme.colors.white}>{text}</Text.caption3>
    </Styles.Wrapper>
  );
};
