import { localStorageClass, LocalStorageItems } from 'utils/localStorage/localStorage';

export type GroupChatLocal = {
  name: string;
  id: string;
};

export const getGroupChat = (): GroupChatLocal | undefined => {
  const data = localStorageClass.getItem(LocalStorageItems.groupChatInvite);

  if (data?.channelId && data?.channelName) {
    return {
      name: data.channelName,
      id: data.channelId,
    };
  }
};
