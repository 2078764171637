import React from 'react';
import { Logo } from 'assets/svgs/Logo';
import { Button } from 'atoms/Button/Button';
import { Text } from 'atoms/Text/Text';
import { defaultTheme } from 'constants/theme/defaultTheme';
import { welcomeTestIDs } from '../../Welcome';
import { HeaderGrad } from '../HeaderGrad/HeaderGrad';
import { Styles } from '../DefaultWelcomeHeader/styles';

type PromoWelcomeHeaderProps = {
  onClick(): void;
  logo?: string;
  title: string;
  description: string;
  image: string;
};

export const PromoWelcomeHeader: React.FC<PromoWelcomeHeaderProps> = ({
  onClick,
  logo,
  title,
  description,
  image,
}) => {
  return (
    <Styles.PromoHeader>
      <HeaderGrad showBgImage>
        <Styles.Hero data-testid={welcomeTestIDs.promoHeader}>
          <Styles.TextCol>
            <Styles.LogoWrapper>
              {!!logo && (
                <Styles.PromoImageWrapper>
                  <Styles.PromoImage alt=" wide logo" src={logo} />
                </Styles.PromoImageWrapper>
              )}
              <Styles.Logo whiteBorder={!!logo}>
                <Logo color="white" />
              </Styles.Logo>
            </Styles.LogoWrapper>
            <Text.display color={defaultTheme.colors.white} responsive>
              {title}
            </Text.display>
            <Styles.TextWraper>
              <Text.h1 color={defaultTheme.colors.white} regular responsive>
                {description}
              </Text.h1>
            </Styles.TextWraper>

            <Styles.ButtonWrapper>
              <Button
                text="Get started"
                testID={welcomeTestIDs.button}
                large
                block
                onClick={onClick}
              />
            </Styles.ButtonWrapper>
          </Styles.TextCol>
          <Styles.ImageCol>
            <Styles.Image
              data-testid={welcomeTestIDs.image}
              className="phone-image-one"
              srcSet={`/images/${image}.png 1x, /images/${image}@2x.png 2x`}
              alt="Image of phones with app on"
            />
          </Styles.ImageCol>
        </Styles.Hero>
      </HeaderGrad>
    </Styles.PromoHeader>
  );
};
