import { OnboardingUser } from 'contexts/OnboardingProvider/types';
import { PATHS } from 'constants/paths';
import { localStorageClass, LocalStorageItems } from 'utils/localStorage/localStorage';

const isStringPopulated = (str: string | undefined) => str && str?.length > 0;

const isArrayPopulated = (arr: unknown[] | undefined) => arr && arr?.length > 0;

export const determineNextOnboardingPage = (user: OnboardingUser): string | null => {
  if (localStorageClass.getItem(LocalStorageItems.onboardingCompleted)) {
    return PATHS.ONBOARDING_SUCCESS;
  }

  if (
    isArrayPopulated(user.areasOfPracticeIds) &&
    (isStringPopulated(user.zipCode) ||
      isStringPopulated(user.businessName) ||
      isArrayPopulated(user.roleIds) ||
      isArrayPopulated(user.sitesOfCareIds))
  ) {
    return PATHS.ONBOARDING_CLINICAL_AREA_DETAILS;
  }

  if (isArrayPopulated(user.areasOfPracticeIds) && isStringPopulated(user.areaOfSpecialisationId)) {
    return PATHS.ONBOARDING_PRODUCT_GROUPS + `?aosId=${user.areaOfSpecialisationId}`;
  }

  if (isStringPopulated(user.id)) {
    return PATHS.ONBOARDING_AOS;
  }

  return null;
};
